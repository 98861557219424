import { useState, useEffect } from 'react'
import Inscripto from './inscripto_aer.js'
import * as XLSX from 'xlsx/xlsx.mjs'

const PORT_BACK = 8004
const SERVER_URL = "https://inscripcion.ar:"

const back = SERVER_URL + PORT_BACK + "/"

export default function Bar2024(props) {
  const [preinscriptos, setPreinscriptos] = useState([])
  const [c_e, setC_E] = useState(0)
  const [apellido, setApellido] = useState('')
  const [nombre, setNombre] = useState('')
  const [email, setEmail] = useState('')
  const [empresa , setEmpresa] = useState('')
  const [total, setTotal] = useState(0)
  const [form, setForm] = useState({})
  const [respuesta, setRespuesta] = useState('0')
  const [mesa, setMesa] = useState('')
  

  const [si, setSi] = useState(0)
  const [no, setNo] = useState(0)
  const [nc, setNc] = useState(0)
  
  
  function traer_pre () {
    fetch(back + 'admin/traer_inscriptos', {
      method: 'GET',
      headers: {
      'Authorization': 'Bearer ' + props.token
    }})
    .then (response => response.json())
    .then (a => {
      const t = a.map((e) => {return {...e, s: false}})  
      setPreinscriptos(t)
      setC_E(0)
      setTotal(t.length)
      setSi(t.filter(a => a.resp==='SI').length)
      setNo(t.filter(a => a.resp==='NO').length)
      setNc(t.filter(a => a.resp==='NC').length)
    })
  }
  useEffect(() => {
    traer_pre()
  }, [])

  function contar() {
    setSi(preinscriptos.filter(a => a.resp==='SI').length)
    setNo(preinscriptos.filter(a => a.resp==='NO').length)
    setNc(preinscriptos.filter(a => a.resp==='NC').length)
  }

  function elegir (z) {
    if (props.quien.rol===1) {
      elegir_uno(z)
      return 
    }
    const t =  preinscriptos.map (e => {
      if (e.id===z)
        e.s = !e.s
      return e
    })
    setPreinscriptos(t)
    const cuantos = t.filter(a => a.s).length
    setC_E(cuantos)
    }

  function elegir_uno (z) {
    const cuantos = preinscriptos.filter(a => a.s)
    if (! cuantos.length) {
      const t =  preinscriptos.map (e => {
        if (e.id===z)
          e.s = true
        return e
      })
      setPreinscriptos(t)
      setC_E(1)
    } else {
      todo(false)
      if (cuantos[0].id !== z) {
        const t = preinscriptos.map (e => {
        if (e.id===z)
          e.s = true
        return e
        })
        setPreinscriptos(t)
        setC_E(1)
      } else {
        const t = preinscriptos.map (e => {
          if (e.id===z)
            e.s = false
          return e
          })
          setPreinscriptos(t)
          setC_E(0)
      }
    }
  }
  
  function todo(z) {
    if (props.quien.rol===1 && z) return
    const t =  preinscriptos.map (e => {
      if ( respuesta === '0' || respuesta === e.resp)
        e.s = z
      else
        e.s = false
      return e
    })
    setPreinscriptos(t)
    setC_E(t.filter(a => a.s).length)
  }

     function update(obj) {
      if (isNaN(obj.mesa)) {
        alert('Error con el número de mesa')
        return
      }
      fetch (back + 'admin/corregir', {
        body: JSON.stringify(obj),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        setForm({...form, id: 0})
        traer_pre()
      })
    }

  function mail() {
    const quien = preinscriptos.filter(e => e.s)
    const pedidosPausados = quien.map(t => {
      const sub_t = {
        apellido: t.apellido,
        nombre: t.nombre,
        email: t.email,
        id: t.id
      }
      return () => fetch (back + 'admin/enviar_mail', {
        body: JSON.stringify(sub_t),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        if(a) {
          const desmarcar = preinscriptos.map (z => {
            if (z.id === sub_t.id)
              z.s = false
            return z
          })
          setPreinscriptos(desmarcar);
          const cuantos = desmarcar.filter(a => a.s).length
          setC_E(cuantos)
        }
      });
    });
    pedidosPausados.reduce((p, pnew) => p.then(() => sleep(3000)).then(pnew), Promise.resolve());
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }

    function enviar_qr() {
      const quien = preinscriptos.filter(e => e.s)
      const pedidosPausados = quien.map(t => {
        const sub_t = {
          apellido: t.apellido,
          nombre: t.nombre,
          email: t.email,
          id: t.id
        };
        return () => fetch (back + 'admin/enviar_mail_con_qr', {
          body: JSON.stringify(sub_t),
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + props.token,
            'Content-Type': 'application/json' 
          }
        })
        .then (response => response.json())
        .then (a => {
          if(a) {
            const desmarcar = preinscriptos.map (z => {
              if (z.id === sub_t.id)
                z.s = false
              return z
            })
            setPreinscriptos(desmarcar);
            const cuantos = desmarcar.filter(a => a.s).length
            setC_E(cuantos)
          }
        });
      });
      pedidosPausados.reduce((p, pnew) => p.then(() => sleep(3000)).then(pnew), Promise.resolve());
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    }

    function eliminar() {
      const t = preinscriptos.filter(e => e.s)
      const sub_t = {id: t[0].id}
      fetch (back + 'admin/eliminar', {
        body: JSON.stringify(sub_t),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        setPreinscriptos(preinscriptos.filter(e => !e.s))
        setC_E(0)
        contar()
      })
    }

    const comida = (c) => {
      const cual = parseInt(c) 
      var res
      switch(cual) {
        case 1: 
          res = 'Ninguna'
          break
        case 2: 
          res = 'Vegetariano'
          break
        case 3:
          res = 'Sin TACC'
          break
        case 4:
          res = 'Vegano'
          break
        default:
          break
        }
        return res
    }

    function descargar() {
      fetch(back + 'admin/traer_inscriptos', {
        method: 'GET',
        headers: {
        'Authorization': 'Bearer ' + props.token
      }})
      .then (response => response.json())
      .then (json => { 
        const nuevo = json.map(e => {
          let {link,bloqueado, ...resto} = e
          resto = {...resto, id: 'AER' + resto.id.toString().padStart(4,'0')}
          resto.res_alim = comida(resto.res_alim)
          resto.tarjeta = resto.tarjeta ? 'Tarjeta' : 'Invitado'
          resto.cuando = resto.cuando.toString().substr(0,10) + ' ' + resto.cuando.toString().substr(11,8)
          const nuevotrim = Object.keys(resto).reduce((acc, clave) => {
            typeof resto[clave]==='string' ? acc[clave] = resto[clave].trim() : acc[clave]= resto[clave]
            return acc;
          }, {});
          return nuevotrim
        })
        const worksheet = XLSX.utils.json_to_sheet(nuevo);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Asociación Empresaria');
        const fecha = new Date()
        const a = fecha.getFullYear().toString()
        const b = (fecha.getMonth() + 1).toString().padStart(2,'0')
        const c = fecha.getDate().toString().padStart(2,'0')
        const d = a + '-' + b +  '-' + c
        const hora = fecha.getHours().toString().padStart(2,'0')
        const minutos = fecha.getMinutes().toString().padStart(2,'0')
        const segundos = fecha.getSeconds().toString().padStart(2,'0')
        const timer = hora + '-' + minutos + '-' + segundos
        XLSX.writeFile(workbook, 'listado_inscriptos ' + d + ' ' + timer + '.xlsx')
    })
  }
  
    function cuales(e) {
      traer_pre()
      setRespuesta(e)
      contar()
    }
    
  return (<>
        <div className="flex flex-row justify-evenly w-full text-lg font-semibold py-2">
          <select 
            className="px-2 outline-0"
            value={respuesta}
            onChange={e => cuales(e.target.value)}
          >
            <option value="0">Todos</option>
            <option value="NC">No Contesta</option>
            <option value="SI">Asiste</option>
            <option value="NO">No Asiste</option>
          </select>
          <div className="flex flex-row w-1/6 justify-around">
            {c_e}
            <label>Seleccionados de</label>
            {respuesta==='0' ? total : ''}
            {respuesta==='NC' ? nc : ''}
            {respuesta==='NO' ? no : ''}
            {respuesta==='SI' ? si : ''}
          </div>
          {props.quien.rol===2 ?
          <button 
            name='Descargar' 
            className="border-2 px-2 border-green-400 bg-green-200 hover:text-white hover:bg-green-800" 
            onClick={() => descargar()}
          >
            Descargar Todo
          </button>
          :
          ''
          }
          <button 
            name='Email' 
            className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800"
            disabled={c_e >= 1 ? false: true}
            onClick={() => mail()}
          >
            Reenviar Invitación
          </button>
           {props.quien.rol===2 ? (<>
           <button 
               name='Eliminarr' 
               className={c_e===1 ?
                "border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
                 :
                 "border-2 px-2 border-gray-400 cursor-not-allowed bg-gray-200"
                }
                disabled={c_e===1 ? false: true}
                onClick={() => eliminar()}
              >
                Eliminar
            </button>
            <button 
            name='Enviar QR' 
            className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
            onClick={() => enviar_qr()}
           >
             Enviar QR
         </button></>)
            :
            ''
            }
        </div>
        
        <div className="pl-2 w-full grid grid-cols-9 bg-orange-200 gap-x-2 place-content-start">
        <div className="">Filtrar</div>
          <div className="flex flex-col w-32">
            <div>Apellido</div>
            <input 
              type="text"
              value={apellido}
              onChange={e => setApellido(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>Nombre</div>
            <input 
              type="text"
              value={nombre}
              onChange={e => setNombre(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>E - mail</div>
            <input 
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>Empresa</div>
            <input 
              type="text"
              value={empresa}
              onChange={e => setEmpresa(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>Mesa</div>
            <input 
              type="text"
              value={mesa}
              onChange={e => setMesa(e.target.value)}
              className="w-12 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0 text-right px-1"
            />
          </div>
          <div>{si + ' SI'}</div>
          <div>{no + ' NO'}</div>
          <div>{nc + ' NC'}</div>
          </div>
          <div className="flex flex-row justify-between w-full text-lg font-semibold border-b-2 border-gray-400">
            <div className="pl-5 grid grid-cols-8">
            {props.quien.rol===2 ?
              <div className="w-4">
                <input 
                  type="checkbox"
                  id="0"
                  onChange={(e) => todo(e.target.checked)}
                />
                </div>
              : '' }
              <div className="w-2"></div>
            </div>
            <div className="grid grid-cols-12 w-full">
            <div>Apellido</div>
            <div>Nombre</div>
            <div>Categoría</div>
            <div className="col-span-2">Mail</div>
            <div>Empresa</div>
            <div>Teléfono</div>
            <div>ID</div>
            <div>Restricción</div>
            <div>Respuesta</div>
            <div>Tarjeta</div>
            <div>Mesa</div>
         </div> 
         </div>
         <div className="px-5 w-full overflow-y-auto text-sm">
        {preinscriptos.map((a) => {
          if (respuesta!=='0'  && a.resp !== respuesta) return
          if ((mesa==='' || a.mesa===parseInt(mesa)) && (a.nombre===null || a.nombre.toLowerCase().includes(nombre.toLowerCase())) && (a.apellido===null || a.apellido.toLowerCase().includes(apellido.toLowerCase())) && (a.email===null || a.email.toLowerCase().includes(email.toLowerCase())) && (a.empresa==null || a.empresa.toLowerCase().includes(empresa.toLowerCase()))) 
             return <Inscripto key={a.id} obj={a} elegir={elegir} setForm={setForm} form={form} update={update} back={back}/>
          })
        }
        <br></br><br></br>
      </div>
      </>)
  }