const ElementoAer = (props) => {

    const prefijo = 'FCO'
    
    
    const formato = 'text-center'

    return (<>
        <div className="w-full flex bg-zinc-200 flex-row justify-between"  onClick={e => props.setUpdData(props.obj)} key={props.obj.id}>
            <input 
                type="checkbox" 
                id={props.obj.id}  
                checked={props.obj.s}
                onChange={(e => props.elegir(props.obj.id))}
            />
             <div className="px-5 grid grid-cols-10 w-full">
              <label>{props.obj.apellido}</label>
              <label>{props.obj.nombre}</label>
              <label className="col-span-2">{props.obj.email}</label>
              <label>{props.obj.documento}</label>
              <label className="col-span-2">{props.obj.institucion}</label>
              <label>{props.obj.telefono}</label>
              <label className={formato}>{prefijo + props.obj.id.toString().padStart(4,'0')}</label>
              {props.obj.tipo==='PG' ? <label>General</label> : ''}
              {props.obj.tipo==='PJ' ? <label>Descuento</label> : ''}
              {props.obj.tipo==='IB' ? <label>Invitado</label> : ''}
            </div>
        </div>
        </>)
}

export default ElementoAer