const Inscripto = (props) => {
  const prefijo = 'FCO'  
  const formato_cajon_visible = "flex flex-row justify-around my-1 visible"
  const formato_cajon_invisible = "flex flex-row justify-around my-1 invisible"
  const formato_data_visible = "flex flex-row justify-between visible"
  const formato_data_invisible = "flex flex-row justify-between invisible"

  const generar_QR = (() => {
    fetch (props.back + 'descargar_qr/id/' + props.updData.id)
    .then (response => response.json())
    .then (a => {if (a==='Ok') 
      window.open("https://inscripcion.ar/servicios/back_construccion/" + prefijo + props.updData.id + '.png')
    })
  })

  const comprobante = (() => {
      window.open("https://inscripcion.ar/servicios/back_construccion/uploads/" + props.updData.imagen)
  })

    
  const update = (() => {
    const ape = props.updData.apellido.length ? props.updData.apellido[0].toUpperCase() + props.updData.apellido.slice(1) : ''
    const nom = props.updData.nombre.length ? props.updData.nombre[0].toUpperCase() + props.updData.nombre.slice(1) : ''
    const a = {...props.updData, apellido: ape, nombre: nom}
    var data = {
      body: JSON.stringify(a),
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json' }
    }
    fetch(props.back + 'admin/corregir',data)
    .then (response => response.json())
    .then (json => {
      props.setPreinscriptos(props.preinscriptos.map(a => a.id === props.updData.id ? json : a))
      props.setUpdData({id:0, apellido:'', nombre:'', email:'', documento:'', telefono:'', empresa:'', cargo:'', tipo:'P', codigo:'', cuando:''})
    })
  })


  return (<>
    <div className="border-2 border-gray-600 w-full my-1 px-2 flex flex-col mb-10 bg-zinc-400">
      <div className="flex flex-row justify-evenly my-1">
        <div className="flex flex-row justify-between">
          <label>Apellido</label>
          <input 
            type="text"
            value={props.updData.apellido}
            onChange={e => props.setUpdData({...props.updData, apellido: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Nombre</label>
          <input 
            type="text"
            value={props.updData.nombre}
            onChange={e => props.setUpdData({...props.updData, nombre: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Mail</label>
          <input 
            type="text"
            value={props.updData.email}
            onChange={e => props.setUpdData({...props.updData, email: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Documento</label>
          <input 
            type="text"
            value={props.updData.documento}
            onChange={e => props.setUpdData({...props.updData, documento: e.target.value})}
            className="ml-5" 
          />
        </div>
      </div>
      <div className="flex flex-row justify-evenly my-1">
        <div className="flex flex-row justify-between w-30">
          <label>Teléfono</label>
          <input 
            type="text"
            value={props.updData.telefono}
            onChange={e => props.setUpdData({...props.updData, telefono: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between">
          <label>Empresa</label>
          <input 
            type="text"
            value={props.updData.empresa}
            onChange={e => props.setUpdData({...props.updData, empresa: e.target.value})}
            className="ml-5 w-64" 
          />
        </div>
        {props.updData.tipo==='I' ?
        <div className="flex flex-row justify-between">
        <label>Código</label>
        <input 
          type="text"
          value={props.updData.codigo}
          onChange={e => props.setUpdData({...props.updData, codigo: e.target.value})}
          className="ml-5 w-64" 
        />
      </div>
      :''}
        
      </div>
      <div className="flex flex-row justify-evenly my-1">
        <div className="flex flex-row justify-between w-30">
          <label>Cargo</label>
          <input 
            type="text"
            value={props.updData.cargo}
            onChange={e => props.setUpdData({...props.updData, cargo: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Inscripto</label>
          <select 
            value={props.updData.tipo}
            onChange={e => props.setUpdData({...props.updData, tipo: e.target.value})}
            className="ml-5"
          >
            <option value='P'>Público</option>
            <option value='I'>Invitado</option>
          </select>
        </div>
        <div className={props.updData.id ? formato_data_visible : formato_data_invisible}>
          <label className="font-lg">{'ID:  ' + prefijo + (props.updData.id).toString().padStart(4,'0')}</label>
        </div>
        <div className={props.updData.id ? formato_data_visible : formato_data_invisible}>
          <label className="font-lg">Registrado el {props.updData.cuando.substr(0,props.updData.cuando.indexOf('T'))}</label> 
          <label className="fornt-lg ml-3">A las  {props.updData.cuando.substr(props.updData.cuando.indexOf('T') + 1, 8)}</label>
        </div>
        </div>
          
          <div className={props.updData.id ? formato_cajon_visible : formato_cajon_invisible}>
          <div className="flex flex-row items-center justify-between w-30">
          <label>Observaciones</label>
          <input 
            type="text"
            value={props.updData.obs}
            onChange={e => props.setUpdData({...props.updData, obs: e.target.value})}
            className="ml-5" 
          />
        </div>
            {props.quien.rol>=2 ?
            <button 
              name='Aceptar' 
              className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800" 
              onClick={() => update()}
            >
            Guardar Cambios
            </button>
            : ''}
            {props.updData.tipo==='P' ?
            <button 
              name='Comprobante' 
              className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
              onClick={e => comprobante()}
            >
            Comprobante
            </button>
            : '' }
            <button 
              name='Generar' 
              className="border-2 px-2 border-green-400 bg-green-200 text-sm hover:text-white hover:bg-blue-800" 
              onClick={e => generar_QR()}
            >
            Ver QR
            </button>
          </div>
        </div>
  </>)
}
      

 export default Inscripto