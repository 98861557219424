const Inscripto = (props) => {
  const prefijo = 'FCO'  
  const formato_cajon_visible = "flex flex-row justify-around my-1 visible"
  const formato_cajon_invisible = "flex flex-row justify-around my-1 invisible"
  const formato_data_visible = "flex flex-row justify-between visible "
  const formato_data_invisible = "flex flex-row justify-between invisible"

  const generar_QR = (() => {
    fetch (props.back + 'descargar_qr/id/' + props.updData.id)
    .then (response => response.json())
    .then (a => {if (a==='Ok') 
      window.open("https://inscripcion.ar/servicios/back_construccion/" + prefijo + props.updData.id + '.png')
    })
  })

  const comprobante = (() => {
      window.open("https://inscripcion.ar/servicios/back_medicina/uploads/" + props.updData.imagen)
  })

  const mostrar_provincias = props.provincias.map(a => {
    return <option value={a.id} key={a.id}>{a.nombre}</option>
  })

    
  const update = (() => {
    const ape = props.updData.apellido.length ? props.updData.apellido[0].toUpperCase() + props.updData.apellido.slice(1) : ''
    const nom = props.updData.nombre.length ? props.updData.nombre[0].toUpperCase() + props.updData.nombre.slice(1) : ''
    const a = {...props.updData, apellido: ape, nombre: nom}
    console.log(a)
    var data = {
      body: JSON.stringify(a),
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json' }
    }
    fetch(props.back + 'admin/corregir',data)
    .then (response => response.json())
    .then (json => {
      props.setPreinscriptos(props.preinscriptos.map(a => a.id === props.updData.id ? json : a))
      props.setUpdData({id:0, apellido:'', nombre:'', email:'', documento:'', telefono:'', institutcion:'', especialidad:'', tipo:'PG', codigo:'', cuando:''})
    })
  })


  return (<>
    <div className="border-2 border-gray-600 w-full my-1 px-2 flex flex-col mb-10 bg-zinc-400">
      <div className="flex flex-row justify-evenly my-1">
        <div className="flex flex-row justify-between">
          <label>Apellido</label>
          <input 
            type="text"
            value={props.updData.apellido}
            onChange={e => props.setUpdData({...props.updData, apellido: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Nombre</label>
          <input 
            type="text"
            value={props.updData.nombre}
            onChange={e => props.setUpdData({...props.updData, nombre: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Mail</label>
          <input 
            type="text"
            value={props.updData.email}
            onChange={e => props.setUpdData({...props.updData, email: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Documento</label>
          <input 
            type="text"
            value={props.updData.documento}
            onChange={e => props.setUpdData({...props.updData, documento: e.target.value})}
            className="ml-5" 
          />
        </div>
      </div>
      <div className="flex flex-row justify-evenly my-1">
        <div className="flex flex-row justify-between w-30">
          <label>Teléfono</label>
          <input 
            type="text"
            value={props.updData.telefono}
            onChange={e => props.setUpdData({...props.updData, telefono: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between">
          <label>Instituticon</label>
          <input 
            type="text"
            value={props.updData.institucion}
            onChange={e => props.setUpdData({...props.updData, institucion: e.target.value})}
            className="ml-5 w-64" 
          />
        </div>
        {props.updData.tipo==='I' ?
        <div className="flex flex-row justify-between">
        <label>Código</label>
        <input 
          type="text"
          value={props.updData.codigo}
          onChange={e => props.setUpdData({...props.updData, codigo: e.target.value})}
          className="ml-5 w-64" 
        />
      </div>
      :''}
        <div className="flex flex-row justify-between w-30">
          <label>Especialidad</label>
          <input 
            type="text"
            value={props.updData.especialidad}
            onChange={e => props.setUpdData({...props.updData, especialidad: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Profesion</label>
          <select 
            value={props.updData.profesion}
            onChange={e => props.setUpdData({...props.updData, profesion: e.target.value})}
            className="ml-5"
          >
            <option value='Medico'>Medico</option>
            <option value='Otra'>Otra</option>
            <option value='Estudiante'>Estudiante</option>
          </select>
        </div>
      </div>
      <div className="flex flex-row justify-evenly my-1">
     
        <div className="flex flex-row justify-between w-30">
          <label>Tipo</label>
          <select 
            value={props.updData.tipo}
            onChange={e => props.setUpdData({...props.updData, tipo: e.target.value})}
            className="ml-5"
          >
            <option value='PG'>Público general</option>
            <option value='PG'>Público con descuento</option>
            <option value='IB'>Invitado</option>
          </select>
        </div>
        <div className="flex flex-row justify-between w-30">
          <label>Código</label>
          <input 
            type="text"
            value={props.updData.codigo}
            onChange={e => props.setUpdData({...props.updData, codigo: e.target.value})}
            className="ml-5" 
          />
        </div>
        <div className={props.updData.id ? formato_data_visible : formato_data_invisible}>
          <label className="font-lg">{'ID:  ' + prefijo + (props.updData.id).toString().padStart(4,'0')}</label>
        </div>
        <div className={props.updData.id ? formato_data_visible : formato_data_invisible}>
          <label className="font-lg">Registrado el {props.updData.cuando.substr(0,props.updData.cuando.indexOf('T'))}</label> 
          <label className="fornt-lg ml-3">A las  {props.updData.cuando.substr(props.updData.cuando.indexOf('T') + 1, 8)}</label>
        </div>
        </div>
        <div className="flex flex-row justify-evenly my-1">
        <div className="flex flex-row justify-between w-30">
          <label>País</label>
          <input 
            type="text"
            value={props.updData.pais}
            onChange={e => props.setUpdData({...props.updData, pais: e.target.value})}
            className="ml-5" 
          />
        </div>
        {props.updData.pais==='Argentina' ?
        <div className="w-32 flex flex-row">
          <label>Provincia</label>
          <select
            type="text"
            value={props.updData.provinciaId}
            onChange={e => props.setUpdData({...props.updData, provinciaId: e.target.value})}
            className="w-20 bg-zinc-50 text-sm border-b-2 border-gray-400 outline-0 ml-5"
          >
            {mostrar_provincias}
          </select>
        </div>
        :''}
        <div className="flex flex-row justify-between w-30">
          <label>Ciudad</label>
          <input 
            type="text"
            value={props.updData.ciudad}
            onChange={e => props.setUpdData({...props.updData, ciudad: e.target.value})}
            className="ml-5" 
          />
        </div>
        </div>   
        <div className="flex flex-row justify-around my-1">
          <div className="flex flex-row justify-between items-center">
            <label>Observaciones</label>
            <input 
              type="text"
              value={props.updData.obs}
              onChange={e => props.setUpdData({...props.updData, obs: e.target.value})}
              className="ml-5 w-96 px-2" 
            />
          </div>
          <div className={props.updData.id ? formato_cajon_visible + ' w-full' : formato_cajon_invisible}>
            {props.quien.rol===2 ?
            <button  
              className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800" 
              onClick={() => update()}
            >
            Guardar Cambios
            </button>
          : ''}
            {props.updData.tipo==='PG' || props.updData.tipo==='PJ' ?
            <button 
              className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
              onClick={e => comprobante()}
            >
            Comprobante
            </button>
          : '' }
            <button 
              className="border-2 px-2 border-green-400 bg-green-200 text-sm hover:text-white hover:bg-blue-800" 
              onClick={e => generar_QR()}
            >
            Ver QR
            </button>
          </div>
        </div>
        </div>
  </>)
}
      

 export default Inscripto