import Cirugia from './cirugia.js'
import AsocEmp from './asocemp.js'
import Construccion from './construccion.js'
import Medicina from './medicina.js'
import Login from './login.js'
import EditorEventos from './Eventos/Editor.js'
import {useState, useEffect} from 'react'
import Barra from './barra.js'

const App = () => {

  const PORT_BACK = 8000
  const SERVER_URL = "https://inscripcion.ar:"

  const back = SERVER_URL + PORT_BACK + "/"

  const [token, setToken] = useState('')
  const [eventos, setEventos] = useState([])
  const [evento,setEvento] = useState(7)
  const [evento_nombre, setEvento_nombre] = useState('')
  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(0)
  const [quien, setQuien] = useState({rol:0})
  const [id, setID] = useState(0)
  const [cual, setCual] =  useState(false)
  const [recargar, setRecargar] = useState(false)
  const formato_viejo="flex flex-col w-screen h-screen bg-orange-200 overflow-hidden"
  const formato_nuevo="flex flex-col w-screen h-screen bg-zinc-500 overflow-hidden"

  function login() {
    const values = {
      usuario: usuario,
      password: password
    }
    const data ={
      body: JSON.stringify(values),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
    fetch(back + 'login', data)
    .then (response => response.json())
    .then (a => {
      if (a.rol=== undefined) {
          
          setToken(a)
          var d = JSON.parse(window.atob(a.split('.')[1]))
          console.log('ahora')
          console.log(d.rol)
          setQuien(d)
          setError(0)
          setID(d.id)
        }
        else {
          setQuien({rol:0})
          setError (1)
        }
      setUsuario('')
      setPassword('')
    })
    .catch(function (error) {
      console.log("Hubo un problema con la petición Fetch:" + error.message)
      setError(2)
    })
  }

useEffect (() => {
  function traer_eventos () {
    const data = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json' 
      }
    }
    fetch(back + 'admin/traer_eventos', data)
    .then (response => response.json())
    .then (json => {
      setEventos(json)
      const cual = json.filter (a => a.id === quien.evento_id)
      if (cual.length) {
        setEvento(cual[0].id)
        setEvento_nombre(cual[0].descripcion)
      } else {
        setEvento(json[0].id)
        setEvento_nombre(json[0].descripcion)
      }
    })
  }
  traer_eventos()
}, [token, recargar, back, id])

return (<>
    <div className={evento>=16? formato_nuevo : formato_viejo}>
      {quien.rol===0 ?
        <Login props usuario={usuario} setUsuario={setUsuario} password={password} setPassword={setPassword} error={error} login={login}/>  
      : 
        '' 
      }
      {! cual ?
        <Barra quien={quien} setCual={setCual} evento_nombre={evento_nombre} evento={evento} setEvento={setEvento} eventos={eventos}/>
      :
        <EditorEventos token={token} eventos={eventos} setCual={setCual} recargar={recargar} setRecargar={setRecargar} quien={quien}/>
      }
      {quien.rol>=1 && evento===14 && ! cual? 
        <Cirugia id={id} quien={quien} evento={evento} token={token} />
      :
        ''
      }
       {quien.rol>=1 && evento===15 && ! cual? 
        <AsocEmp id={id} quien={quien} evento={evento} token={token} />
      :
        ''
      }
       {quien.rol>=1 && evento===16 && ! cual? 
        <Construccion id={id} quien={quien} evento={evento} token={token} />
      :
        ''
      }
        {quien.rol>=1 && evento===17 && ! cual? 
        <Medicina id={id} quien={quien} evento={evento} token={token} />
      :
        ''
      }
    </div>  
  </>)
}
export default App