const ElementoAer = (props) => {

    const prefijo = 'FCO'
    
    
    const formato = 'text-center'

    return (<>
        <div className="w-full flex bg-zinc-200 flex-row justify-between"  onClick={e => props.setUpdData(props.obj)} key={props.obj.id}>
            <input 
                type="checkbox" 
                id={props.obj.id}  
                checked={props.obj.s}
                onChange={(e => props.elegir(props.obj.id))}
            />
             <div className="px-5 grid grid-cols-11 w-full">
              <label>{props.obj.apellido===props.obj.apellido.substring(0,13) ? props.obj.apellido : props.obj.apellido.substring(0,13) + '...'}</label>
              <label>{props.obj.nombre}</label>
              <label className="col-span-2">{props.obj.email.substring(0,30)===props.obj.email ? props.obj.email : props.obj.email.substring(0,30) + '...'}</label>
              <label>{props.obj.documento}</label>
              <label className="col-span-2">{props.obj.empresa}</label>
              <label>{props.obj.cargo}</label>
              <label>{props.obj.telefono}</label>
              <label className={formato}>{prefijo + props.obj.id.toString().padStart(4,'0')}</label>
              <label>{props.obj.tipo==='P' ? 'Público' : 'Invitado'}</label>
            </div>
        </div>
        </>)
}

export default ElementoAer