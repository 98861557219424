import { FaAngleDown, FaAngleUp }  from "react-icons/fa"
const prefijo = 'CIR'
export default function Inscripto (props) {

  function generar_QR(id) {
    fetch (props.back + 'descargar_qr/id/' + id)
    .then (response => response.json())
    .then (a => {if (a==='Ok') 
      window.open("https://inscripcion.ar/servicios/back_cirugia/" + prefijo + id + '.png')
    })
  }

  function generar_imagen(id) {
      window.open("https://inscripcion.ar/servicios/back_cirugia/uploads/" + props.obj.imagen)
  }
  function generar_imagen_comp(id) {
    window.open("https://inscripcion.ar/servicios/back_cirugia/uploads/" + props.obj.comprobante)
  } 

  const estado_pago = (() => {
    switch (props.obj.pagado) {
        case 1:
          return 'Sin informar'
        case 2:
          return 'Informado'
        default:
          return ''
    }
  })

  
  return (<>
    <div className="flex flex-col bg-orange-200">
    <div className="flex flex-row justify-between items-center justify-items-start bg-orange-200" key={props.form.id}>
        <div className="flex flex-row w-full items-start">
            <div className="w-20 flex flex-row justify-between pr-5">
                <input 
                  type="checkbox" 
                  id={props.form.id}  
                  checked={props.obj.s}
                  onChange={(e => props.elegir(props.obj.id))}
                />
                {props.obj.id !== props.form.id ?
                  <FaAngleDown 
                    className="text-lg"
                    onClick={() => props.setForm(props.obj)}
                  />
                :
                  <FaAngleUp  
                    className="text-lg"
                    onClick={() => props.setForm({...props.obj, id: 0})}
                  />
                }
            </div>
            <div className="px-5 grid grid-cols-9 w-full">
              <div className="min-w-1/10">{props.obj.apellido}</div>
              <div className="min-w-1/10">{props.obj.nombre}</div>
              <div className="col-span-2">{props.obj.email}</div>
              <div className="">{props.obj.documento}</div>
              <div className="min-w-1/10">{props.obj.telefono}</div>
              <div className="min-w-1/10">{prefijo + (props.obj.id).toString().padStart(4,'0')}</div>
              <div className="min-w-1/10">{props.obj.categoria}</div>
              <div className="min-w-1/10">{estado_pago()}</div>
            </div>
        </div> 
        </div>
        {props.obj.id === props.form.id ?
          <div className="border-2 border-gray-600 w-full my-1 p-2 flex flex-col">
            <div className="flex flex-row justify-evenly my-1">
              <div className="flex flex-row justify-between w-30">
                <label>Apellido</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.apellido}
                  onChange={(e => props.setForm({...props.form, apellido: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
                <label>Nombre</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.nombre}
                  onChange={(e => props.setForm({...props.form, nombre: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
               <label>Mail</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.email}
                  onChange={(e => props.setForm({...props.form, email: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
               <label>Documento</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.documento}
                  onChange={(e => props.setForm({...props.form, documento: e.target.value}))}
                />
              </div>
              </div>
              <div className="flex flex-row justify-evenly my-1">
              <div className="flex flex-row justify-between w-30">
                <label>Teléfono</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.telefono}
                  onChange={(e => props.setForm({...props.form, telefono: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
                <label>Institución</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.institucion}
                  onChange={(e => props.setForm({...props.form, institutcion: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
                <label>Especialidad</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.especialidad}
                  onChange={(e => props.setForm({...props.form, especialidad: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
                <label>Categoría</label>
                <select 
                  className="ml-5"
                  value={props.form.categoria}
                  onChange={(e => props.setForm({...props.form, categoria: e.target.value}))}
                >
                  <option value="Socio">Sociio</option>
                  <option value="NoSocio">No Socio</option>
                  <option value="Residente">Residente</option>
                  <option value="Estudiante">Estudiante</option>
                  <option value="Extranjero">Extranjero</option>
                  <option value='Becado'>Becado</option>
                </select>
              </div>
              </div>
              <div className="flex flex-row justify-evenly my-1">
              <div className="flex flex-row justify-between w-30">
                <label>Provincia</label>
                <select 
                  className="ml-5"
                  value={props.form.provinciaId}
                  onChange={(e => props.setForm({...props.form, provinciaId: e.target.value}))}
                >
                  {props.provincias.map(a => {
                    return (
                    <option value={a.id}>{a.nombre}</option>
                    )
                })}
                </select>
                </div>
                <div className="flex flex-row justify-between w-30">
                <label>Pago</label>
                <select 
                  className="ml-5"
                  value={props.form.pagado}
                  onChange={e => props.setForm({...props.form, pagado: e.target.value})}
                >
                  <option value='1'>Sin Informar</option>
                  <option value='2'>Informado</option>
                </select>
                </div>
              <div className="flex flex-row justify-between w-30">
                  <label className="font-lg">{'ID:  ' + prefijo + (props.obj.id).toString().padStart(4,'0')}</label>
              </div>
              {props.obj.categoria==='Becado' ?
                <div className="flex flex-row justify-between w-30">
                  <label className="font-lg">Código {props.obj.becado}</label>
                </div>
              :''
              }
              <div className="flex flex-row justify-between w-30">
                  <label className="font-lg">Registrado el {props.form.cuando.substr(0,props.form.cuando.indexOf('T'))}</label> 
                   <label className="fornt-lg ml-3">A las  {props.form.cuando.substr(props.form.cuando.indexOf('T') + 1, 8)}</label>
              </div>
            </div>
              <div className="flex flex-row justify-around my-1">
              <div className="flex flex-row justify-between w-30">
                <label>Observaciones</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.obs}
                  onChange={(e => props.setForm({...props.form, obs: e.target.value}))}
                />
              </div>
                 <button 
                   name='Aceptar' 
                   className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800" 
                   onClick={() => props.update(props.form)}
                 >
                 Guardar Cambios
                 </button>
                 {props.obj.imagen!=='' ?
                 <button  
                   className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-blue-800" 
                   onClick={e => generar_imagen(props.form.id)}
                 >
                 Ver Certificado
                 </button>
                 : '' }
                 {props.form.comprobante!==null ?
                 <button  
                   className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-blue-800" 
                   onClick={e => generar_imagen_comp(props.form.id)}
                 >
                 Ver Comprobante
                 </button>
                 : '' }
                 <button 
                   name='Generar' 
                   className="border-2 px-2 border-green-400 bg-green-200 text-sm hover:text-white hover:bg-blue-800" 
                   onClick={e => generar_QR(props.form.id)}
                 >
                 Ver QR
                 </button>
                 </div>
              </div>
        :
        ''
        }
    </div>
  </>)
}