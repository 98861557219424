import { useState, useEffect } from 'react'
import Inscripto from './inscripto_construccion.js'
import Elemento from './elemento_construccion.js'
import * as XLSX from 'xlsx/xlsx.mjs'


const PORT_BACK = 8006
const SERVER_URL = "https://inscripcion.ar:"

const back = SERVER_URL + PORT_BACK + "/"

export default function Bar2024(props) {
  const [preinscriptos, setPreinscriptos] = useState([])
  const [c_e, setC_E] = useState(0)
  const [apellido, setApellido] = useState('')
  const [nombre, setNombre] = useState('')
  const [email, setEmail] = useState('')
  const [empresa , setEmpresa] = useState('')
  const [respuesta, setRespuesta] = useState('0')
  const [updData, setUpdData] = useState({id: 0, resp: 'NC', apellido: '', res_alim: 1, tarjeta: false, cuando: '', mesa: 0})
  const [sponsor, setSponsor] = useState('0')
  const [sponsors, setSponsors] = useState([])
  const [soloPublico, setSoloPublico] = useState(false)
  const formato_eitqueta = "text-gray-50"
  const formato_cartel = "text-gray-50 col-span-2 font-semibold text-lg"
  
  const filtrar = () => {
    const data = preinscriptos.map((a) => {
      if (soloPublico) {
        if (a.tipo[0]==='P') {
          if ((a.nombre===null || a.nombre.toLowerCase().includes(nombre.toLowerCase())) && (a.apellido===null || a.apellido.toLowerCase().includes(apellido.toLowerCase())) && (a.email===null || a.email.toLowerCase().includes(email.toLowerCase())) && (a.empresa===null || a.empresa.toLowerCase().includes(empresa.toLowerCase())) )  
           return <Elemento key={a.id} obj={a} elegir={elegir} setUpdData={setUpdData}/> 
        }
      } else {
        if (! (a.codigo!==null && (sponsor==='0' || a.codigo.trim().toLowerCase()===sponsor.trim()))) return
        if ((a.nombre===null || a.nombre.toLowerCase().includes(nombre.toLowerCase())) && (a.apellido===null || a.apellido.toLowerCase().includes(apellido.toLowerCase())) && (a.email===null || a.email.toLowerCase().includes(email.toLowerCase())) && (a.empresa===null || a.empresa.toLowerCase().includes(empresa.toLowerCase())) )  
          return <Elemento key={a.id} obj={a} elegir={elegir} setUpdData={setUpdData}/>
      }
    })
    return data
  }

    function traer_pre () {
    fetch(back + 'admin/traer_inscriptos', {
      method: 'GET',
      headers: {
      'Authorization': 'Bearer ' + props.token
    }})
    .then (response => response.json())
    .then (a => {
      const t = a.map((e) => {return {...e, s: false}})  
      setPreinscriptos(t)
      
      setC_E(0)
    })
  }

  function traer_invitados() {
    fetch(back + 'admin/traer_invitados', {
      method: 'GET',
      headers: {
      'Authorization': 'Bearer ' + props.token
    }})
    .then (response => response.json())
    .then (a => setSponsors(a))
  }

  function actualizar() {
    traer_pre()
    traer_invitados()
    setSponsor('0')
  }
  useEffect(() => {
    actualizar()
  }, [])

    const opciones = () => sponsors.map((e) => {
      return <option key={e.codigo} value={e.codigo}>{e.codigo}</option>
    })

    const usados = () => {
      if (sponsor!=='0') 
        return sponsors.filter(e => e.codigo === sponsor)[0].usado
      else
        return sponsors.reduce((acumulador, persona) => acumulador + persona.usado, 0)
    }

    const total = () => {
      if (sponsor!=='0') 
        return sponsors.filter(e => e.codigo === sponsor)[0].total
      else
        return sponsors.reduce((acumulador, persona) => acumulador + persona.total, 0)
    }

    const publico = () => {
      return preinscriptos.filter(e => e.tipo === 'P').length
    }


   function elegir (z) {
    if (props.quien.rol===1) {
      elegir_uno(z)
      return 
    }
    const t =  preinscriptos.map (e => {
      if (e.id===z)
        e.s = !e.s
      return e
    })
    setPreinscriptos(t)
    const cuantos = t.filter(a => a.s).length
    setC_E(cuantos)
    }

  function elegir_uno (z) {
    const cuantos = preinscriptos.filter(a => a.s)
    if (! cuantos.length) {
      const t =  preinscriptos.map (e => {
        if (e.id===z)
          e.s = true
        return e
      })
      setPreinscriptos(t)
      setC_E(1)
    } else {
      todo(false)
      if (cuantos[0].id !== z) {
        const t = preinscriptos.map (e => {
        if (e.id===z)
          e.s = true
        return e
        })
        setPreinscriptos(t)
        setC_E(1)
      } else {
        const t = preinscriptos.map (e => {
          if (e.id===z)
            e.s = false
          return e
          })
          setPreinscriptos(t)
          setC_E(0)
      }
    }
  }
  
  function todo(z) {
    if (props.quien.rol===1 && z) return
    const t =  preinscriptos.map (e => {
      if ( respuesta === '0' || respuesta === e.resp)
        e.s = z
      else
        e.s = false
      return e
    })
    setPreinscriptos(t)
    setC_E(t.filter(a => a.s).length)
  }

  function mail() {
    const quien = preinscriptos.filter(e => e.s)
    const pedidosPausados = quien.map(t => {
      const sub_t = {
        apellido: t.apellido,
        nombre: t.nombre,
        email: t.email,
        id: t.id
      }
      return () => fetch (back + 'admin/enviar_mail', {
        body: JSON.stringify(sub_t),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        if(a) {
          const desmarcar = preinscriptos.map (z => {
            if (z.id === sub_t.id)
              z.s = false
            return z
          })
          setPreinscriptos(desmarcar);
          const cuantos = desmarcar.filter(a => a.s).length
          setC_E(cuantos)
        }
      });
    });
    pedidosPausados.reduce((p, pnew) => p.then(() => sleep(3000)).then(pnew), Promise.resolve());
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }

    function enviar_qr() {
      const quien = preinscriptos.filter(e => e.s)
      const pedidosPausados = quien.map(t => {
        const sub_t = {
          apellido: t.apellido,
          nombre: t.nombre,
          email: t.email,
          id: t.id
        };
        return () => fetch (back + 'admin/enviar_mail_con_qr', {
          body: JSON.stringify(sub_t),
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + props.token,
            'Content-Type': 'application/json' 
          }
        })
        .then (response => response.json())
        .then (a => {
          if(a) {
            const desmarcar = preinscriptos.map (z => {
              if (z.id === sub_t.id)
                z.s = false
              return z
            })
            setPreinscriptos(desmarcar);
            const cuantos = desmarcar.filter(a => a.s).length
            setC_E(cuantos)
          }
        });
      });
      pedidosPausados.reduce((p, pnew) => p.then(() => sleep(3000)).then(pnew), Promise.resolve());
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    }

    function eliminar() {
      const t = preinscriptos.filter(e => e.s)
      const sub_t = {id: t[0].id}
      fetch (back + 'admin/eliminar', {
        body: JSON.stringify(sub_t),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        setPreinscriptos(preinscriptos.filter(e => !e.s))
        setC_E(0)
      })
    }

    function descargar() {
      fetch(back + 'admin/traer_inscriptos', {
        method: 'GET',
        headers: {
        'Authorization': 'Bearer ' + props.token
      }})
      .then (response => response.json())
      .then (json => { 
        const nuevo = json.map(e => {
          let {imagen, ...resto} = e
          resto = {...resto, id: 'FCO' + resto.id.toString().padStart(4,'0'), cuando: resto.cuando.toString().substr(0,10) + ' ' + resto.cuando.toString().substr(11,8)}
          const nuevotrim = Object.keys(resto).reduce((acc, clave) => {
            typeof resto[clave]==='string' ? acc[clave] = resto[clave].trim() : acc[clave]= resto[clave]
            return acc;
          }, {});
          return nuevotrim
        })
        const worksheet = XLSX.utils.json_to_sheet(nuevo);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Foro de la Construcción');
        const fecha = new Date()
        const a = fecha.getFullYear().toString()
        const b = (fecha.getMonth() + 1).toString().padStart(2,'0')
        const c = fecha.getDate().toString().padStart(2,'0')
        const d = a + '-' + b +  '-' + c
        const hora = fecha.getHours().toString().padStart(2,'0')
        const minutos = fecha.getMinutes().toString().padStart(2,'0')
        const segundos = fecha.getSeconds().toString().padStart(2,'0')
        const timer = hora + '-' + minutos + '-' + segundos
        XLSX.writeFile(workbook, 'listado_inscriptos ' + d + ' ' + timer + '.xlsx')
    })
  }
  
  return (<>
        <div className="flex flex-row justify-evenly w-full text-lg bg-zinc-500 font-semibold py-2">
          <button 
            name='Descargar' 
            className="border-2 px-2 border-gray-400 bg-gray-200 hover:text-white hover:bg-gray-800" 
            onClick={() => actualizar()}
          >
            Actualizar
          </button>
        {props.quien.rol===2 ?
          <button 
            name='Descargar' 
            className="border-2 px-2 border-green-400 bg-green-200 hover:text-white hover:bg-green-800" 
            onClick={() => descargar()}
          >
            Descargar Todo
          </button>
          :
          ''
          }
           { 0 && props.quien.rol===2 ?
          <button 
              name='Email' 
              className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800"
              disabled={c_e >= 1 ? false: true}
              onClick={() => mail()}
              >
              Reenviar Invitación
           </button>
           :
           ''
          }
           {props.quien.rol===2 ? (<>
           <button 
               name='Eliminarr' 
               className={c_e===1 ?
                "border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
                 :
                 "border-2 px-2 border-gray-400 cursor-not-allowed bg-gray-200"
                }
                disabled={c_e===1 ? false: true}
                onClick={() => eliminar()}
              >
                Eliminar
            </button>
            <button 
            name='Enviar QR' 
            className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
            onClick={() => enviar_qr()}
           >
             Enviar QR
         </button></>)
            :
            ''
            }
        </div>
        
        <div className="mx-2 p-1  grid grid-cols-9 bg-zinc-500 gap-x-2 place-content-start border-2 border-white items-center">
        <div className={formato_eitqueta}>Filtrar por</div>
          <div className="flex flex-col w-32">
            <label className={formato_eitqueta}>Apellido </label>
            <input 
              type="text"
              value={apellido}
              onChange={e => setApellido(e.target.value)}
              className="w-20 bg-zinc-50 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
          <label className={formato_eitqueta}>Nombre</label>
            <input 
              type="text"
              value={nombre}
              onChange={e => setNombre(e.target.value)}
              className="w-20 bg-zinc-50 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
          <label className={formato_eitqueta}>E - mail</label>
            <input 
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="w-20 bg-zinc-50 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
          <label className={formato_eitqueta}>Empresa</label>
            <input 
              type="text"
              value={empresa}
              onChange={e => setEmpresa(e.target.value)}
              className="w-20 bg-zinc-50 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
          <label className={formato_eitqueta}>Sponsor</label>
            <select
              value={sponsor}
              onChange={e => setSponsor(e.target.value)}
              className="bg-zinc-50 text-sm text-lg px-2 outline-0"
            >
            <option value="0">Todos</option>
            {opciones()}
            </select>
          </div>
          <div className="w-32 flex flex-row">
          <input 
                type="checkbox"  
                checked={soloPublico}
                className="mr-5"
                onChange={e => setSoloPublico(! soloPublico)}
            />
          <label className={formato_eitqueta}> Ver Público</label>
          </div>
          <div className="flex flex-col items-center">
            <label className={formato_eitqueta}>invitados</label>
            <label className={formato_eitqueta}>{usados() + ' de ' + total()}</label>
          </div>
          <div className="flex flex-col items-center">
            <label className={formato_eitqueta}>Público</label>
            <label className={formato_eitqueta}>{publico()}</label>
          </div>
        </div>
        <div className="px-2 w-full text-sm h-3/4 bg-zinc-500">
          <div className="w-full flex flex-row justify-between border-b-2 border-gray-600 font-semibold">
            <input 
                  type="checkbox"
                  id="0"
                  onChange={(e) => todo(e.target.checked)}
            />
            <div className="grid grid-cols-11 px-5 w-full text-gray-50">
                <label>Apellido</label>
                <label>Nombre</label>
                <label className="col-span-2">Mail</label>
                <label>Documento</label>
                <label className="col-span-2">Empresa</label>
                <label>Cargo</label>
                <label>Teléfono</label>
                <label>ID</label>
                <label>Tipo</label>
            </div> 
          </div>
        <div className='overflow-y-auto h-4/6'>
          {filtrar()}
        </div>
        <div className="mt-3">
          <Inscripto quien={props.quien} setPreinscriptos={setPreinscriptos} preinscriptos={preinscriptos} traer_pre={traer_pre} token={props.token} back={back} updData={updData} setUpdData={setUpdData}/>
        </div>
      </div>
      </>)
  }